<template>
  <div>
    <div class="video_v">
      <el-upload
        ref="upcover"
        class="upload-demo"
        action="#"
        :limit="1"
        :show-file-list="false"
        :file-list="fileList"
        :http-request="coverUpload"
        :before-upload="beforeUpload"
      >
        <div class="click_up">
          <div v-if="!infoUrl">
            <i class="el-icon-plus"></i>
            <span>点击添加视频</span>
            <p>上传视频格式支持mp4，avi，mov</p>
          </div>
          <div class="cover_img">
            <img v-if="!$route.query.id" :src="gqbpath + fileVideo.url" alt />
            <img v-if="$route.query.id" :src="gqbpath + infoUrl" alt />
          </div>
          <div v-if="$route.query.id" class="algin_up">
            <p class="el-icon-refresh">重新上传</p>
          </div>
        </div>
      </el-upload>
      <div class="info_video" v-if="infoUrl">
        <video class="videos" :src="infoUrl" controls="controls" muted :autoplay="false" loop poster="el-icon-refresh"></video>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Gqburl from '../../components/global.vue'
import axios from 'axios';
export default {
  data () {
    return {
      gqbpath: Gqburl.userSite,
      fileList: [],
      fileVideo: {},
      infoUrl: '',//回显路径
    }
  },
  created () {
    if (this.$route.query.type == 'video') {
      this.axios.get('/api/website/view_article', {
        params: {
          id: this.$route.query.id,
          type: 'video'
        }
      }).then((res) => {
        this.infoUrl = res.data.video_url
      })
    }
  },
  methods: {
    coverUpload (param) {
      console.log(param, 888)
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      this.upload("/api/website/upload_file", form).then((res) => {
        this.fileVideo = res
        console.log(res, 99)
        this.$message({
          type: "success",
          message: '上传成功',
        });
      });
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    beforeUpload (file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg == "mp4" ||
        testmsg == "mov" ||
        testmsg == "avi"
      // const isLt50M = file.size / 1024 / 1024 < 10;
      if (!extension) {
        this.$message({
          message: "上传视频只能是mp4 / avi / mov格式!",
          type: "error"
        });
        return false; //必须加上return false; 才能阻止
      }
      // console.log(file);
      // if (!isLt50M) {
      //   this.$message({
      //     message: "上传文件大小不能超过 10MB!",
      //     type: "error"
      //   });
      //   return false;
      // }
      // return extension || isLt50M;
    }
  }
}
  </script>
  
  <style scoped>
.videos {
  max-width: 300px;
  height: 242px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.video_v {
  position: relative;
  overflow: hidden;
}
.info_video {
  position: absolute;
  left: 50%;
  top: 0px;
  /* max-width: 350px; */
  cursor: pointer;
  /* text-align: center; */
}
.info_video:hover .algin_up {
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 0.5 all;
  z-index: 15;
}
.algin_up {
  position: absolute;
  background: #000;
  z-index: 15;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0.5;
  display: none;
}
div/deep/.algin_up p {
  color: #fff;
  font-size: 24px;
}
.click_up:hover .algin_up {
  display: flex;
  align-items: center;
  justify-content: space-around;
  transition: 0.5 all;
  z-index: 15;
}
.cover_img {
  position: absolute;
  top: 0;
  left: 0;
}
.cover_img img {
  width: 100%;
  z-index: 9;
}
.click_up {
  width: 370px;
  height: 240px;
  border: 1px dashed #0f38ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  overflow: hidden;
}

.click_up i,
.click_up span,
.click_up p {
  display: block;
}
.click_up i {
  font-size: 50px;
}
.click_up span {
  line-height: 16px;
  margin-top: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #0f38ff;
}
.click_up p {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  line-height: 14px;
  color: #909399;
}
</style>