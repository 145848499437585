<template>
  <div>
    <!-- 申报教程新增编辑页面 -->
    <Header
      back="返回"
      title="云学院"
      index="首页"
      titleOne="官网管理"
      titleTwo="云学院"
      titleThree="申报教程"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <span>视频</span>
      <div class="card_box">
        <div class="flex_l">
          <UploadC ref="cover" />
        </div>
        <div class="flex_r">
          <UploadV ref="video" />
        </div>
      </div>
      <!-- 上传封面组件 -->
      <el-form
        style="margin-top: 20px;"
        label-position="top"
        ref="form"
        :rules="formRules"
        :model="videoParams"
      >
        <el-form-item label="标题名称" prop="title">
          <el-input v-model="videoParams.title"></el-input>
        </el-form-item>
        <div style="display: flex;">
          <el-form-item label="模板推荐" style="width: 35%;" prop="recommend">
            <el-select style="width: 90%;" v-model="videoParams.recommend" placeholder="请选择是否">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="视频来源" style="width: 65%;">
            <el-input v-model="videoParams.source_url"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div style="width: 100%;text-align: center;">
        <el-button type="primary" :loading="btnLoading" class="sure" @click="sure()">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Gqburl from '../../components/global.vue'
import UploadC from '../../components/upload/uploadCover.vue'
import UploadV from '../../components/upload/uploadVideo.vue'
export default {
  components: {
    UploadC,
    UploadV,
  },
  data () {
    return {
      btnLoading: false,//提交按钮动画
      infoCover: '',//回显封面图
      infoVideo: '',//回显视频
      childUrl: '',
      childVideo: '',//子组件编辑视频路径
      gqbpath: Gqburl.userSite,
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      // form: {},
      fileList: [],
      videoParams: {
        id: '',
        cover_url: '',//封面路径
        video_url: '',//视频路径
        type: 'video',
      },
      formRules: {
        title: [
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        recommend: [
          { required: true, message: '请选择是否推荐', trigger: 'blur' },
        ]
      },
    }
  },
  created () {
    if (this.$route.query.id) {
      this.videoParams.id = this.$route.query.id
      this.videoView()
    }
  },
  methods: {
    videoView () {
      this.axios.get('/api/website/view_article', {
        params: {
          id: this.$route.query.id,
          type: 'video'
        }
      }).then((res) => {
        this.videoParams = res.data
        this.infoCover = res.data.cover_url
        this.infoVideo = res.data.video_url
      })
    },
    sure () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.$route.query.id) {
            this.videoParams.cover_url = this.gqbpath + this.infoCover
            this.videoParams.video_url = this.gqbpath + this.infoVideo
          } else {
            this.videoParams.cover_url = this.gqbpath + this.$refs.cover.fileCover.url
            this.videoParams.video_url = this.gqbpath + this.$refs.video.fileVideo.url
          }
          this.btnLoading = true
          this.axios.post('/api/website/store_article', { ...this.videoParams, type: 'video' }).then((res) => {
            this.$message.success(res.message)
            this.$router.go(-1)
            this.btnLoading = false
          }).catch(() => {
            this.btnLoading = false
          })
        } else {
          this.$message.warning('请完善信息')
        }
      })
    },
  }
}
</script>

<style scoped>
.sure {
  margin: 50px auto;
}

/* .add_video {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.add_video i {
  display: block;
  font-size: 50px;
}

.add_video span {
  display: block;
  line-height: 16px;
  margin-top: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  color: #0f38ff;
}

.add_video p {
  font-size: 14px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  line-height: 14px;
  color: #909399;
} */

.card_box {
  display: flex;
  margin-top: 15px;
}

.flex_l {
  position: relative;
}

.flex_r {
  position: relative;
  margin-left: 20px;
}

div/deep/.el-upload--picture-card {
  width: 370px !important;
  height: 234px !important;
  margin-top: 15px;
}

/* div/deep/.el-upload--picture-card{
    position: relative;
  } */

div/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 370px;
  height: 234px;
  position: absolute;
  top: 15px;
  z-index: 9;
}

div/deep/.el-upload-list--picture-card .el-upload-list__item-actions {
  top: 0;
}
</style>